import React, { useContext } from "react";
import { Typography } from "@material-ui/core";
import PageSpinner from "../components/UI/Spinners/PageSpinner";
import themeContext from "../contexts/theme/themeContext";

const Loader = ({ text }) => {
  const { gradient_primary } = useContext(themeContext);
  return (
    <div
      className={`fullheightVH flex__centerlize ${gradient_primary ||
        "bg-primary"}`}
    >
      <div className="py-1 bg-logo-sm" />
      <Typography className="text-light py-1 cairo">{text}</Typography>
      <PageSpinner color="light" />
    </div>
  );
};

export default Loader;
