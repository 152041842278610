import React from "react";
import PhoneIcon from "@material-ui/icons/Phone";
import PhoneAndroidIcon from "@material-ui/icons/PhoneAndroid";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import TwitterIcon from "@material-ui/icons/Twitter";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import EditIcon from "@material-ui/icons/Edit";
import { Typography, Link } from "@material-ui/core";
import { useSelector } from "react-redux";

const Accordions = ({ bg, contact, setEdit }) => {
  const auth = useSelector((state) => state.auth);
  return (
    <>
      {contact.tel ? (
        <div className="flex py-s5">
          <Item bg={bg}>
            <Typography variant="caption" className="text-light">
              <PhoneIcon
                fontSize="small"
                style={{ display: "flex", alignSelf: "center" }}
              />
            </Typography>
          </Item>
          <Link href={`tel:${contact.tel}`}>
            <Typography variant="h6">{contact.tel}</Typography>
          </Link>
        </div>
      ) : null}
      {contact.mobile ? (
        <div className="flex py-s5">
          <Item bg={bg}>
            <Typography variant="caption" className="text-light">
              <PhoneAndroidIcon
                fontSize="small"
                style={{ display: "flex", alignSelf: "center" }}
              />
            </Typography>
          </Item>
          <Link href={`tel:${contact.mobile}`}>
            <Typography variant="h6">{contact.mobile}</Typography>
          </Link>
        </div>
      ) : null}
      {contact.whatsapp ? (
        <div className="flex py-s5">
          <Item bg={bg}>
            <Typography variant="caption" className="text-light">
              <WhatsAppIcon
                fontSize="small"
                style={{ display: "flex", alignSelf: "center" }}
              />
            </Typography>
          </Item>
          <Link href={`https://wa.me/966${contact.whatsapp.slice(1)}`}>
            <Typography variant="h6">{contact.whatsapp}</Typography>
          </Link>
        </div>
      ) : null}
      {contact.twitter ? (
        <div className="flex py-s5">
          <Item bg={bg}>
            <Typography variant="caption" className="text-light">
              <TwitterIcon
                fontSize="small"
                style={{ display: "flex", alignSelf: "center" }}
              />
            </Typography>
          </Item>
          <Link href={`https://twitter.com/${contact.twitter}`}>
            <Typography variant="h6">{contact.twitter}</Typography>
          </Link>
        </div>
      ) : null}
      {contact.email ? (
        <div className="flex py-s5">
          <Item bg={bg}>
            <Typography variant="caption" className="text-light">
              <MailOutlineIcon
                fontSize="small"
                style={{ display: "flex", alignSelf: "center" }}
              />
            </Typography>
          </Item>
          <Link href={`mailto:${contact.email}`}>
            <Typography variant="h6">{contact.email}</Typography>
          </Link>
        </div>
      ) : null}
      {auth.token && (
        <EditIcon
          fontSize="large"
          style={{ display: "flex", alignSelf: "flex-end" }}
          className="hoverable-op"
          onClick={() => setEdit(contact)}
        />
      )}
    </>
  );
};

const Item = ({ bg, children }) => {
  return (
    <div
      style={{
        width: 30,
        height: 30,
        borderRadius: 50,
      }}
      className={`${bg} flex flex-justify-center align-items-center mx`}
    >
      {children}
    </div>
  );
};
export default Accordions;
