import * as actionTypes from "./types";
import axios from "axios";
import { server } from "../../util/constants";
import setAuthHeaders from "../../util/setHeader";
const setErrors = (errors) => {
  return {
    type: actionTypes.CONTACTS_ERRORS,
    errors,
  };
};
const loading = () => {
  return {
    type: actionTypes.CONTACTS_LOADING,
  };
};

export const getContacts = (query) => {
  return async (dispatch) => {
    dispatch(loading());
    try {
      const { search, page, pageSize } = query;
      const res = await axios.get(
        `${server}/contacts?q=${search}&page=${page}&pageSize=${pageSize}`
      );
      dispatch({ type: actionTypes.SET_CONTACTS, contacts: res.data });
    } catch (err) {
      dispatch(setErrors(err.response.data));
    }
  };
};

export const saveContact = (body, isEdit) => {
  return async (dispatch, getState) => {
    const { auth } = getState();
    setAuthHeaders(auth.token);
    dispatch(loading());
    try {
      if (!isEdit) {
        const res = await axios.post(`${server}/contacts`, body);
        dispatch({ type: actionTypes.NEW_CONTACT, contact: res.data.contact });
      } else {
        const res = await axios.put(`${server}/contacts/${isEdit.id}`, body);
        console.log(res.data);
        dispatch({
          type: actionTypes.UPDATE_CONTACT,
          contact: res.data.contact,
        });
      }
    } catch (err) {
      dispatch(setErrors(err.response.data));
      throw err.response ? err.response.data : err;
    }
  };
};
