import React, { useState, useContext, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "../../store/actions";
import themeContext from "../../contexts/theme/themeContext";
import {
  Container,
  Typography,
  LinearProgress,
  TextField,
  InputAdornment,
  Select,
  MenuItem,
} from "@material-ui/core";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Accordion from "./Accordion";
import Dialog from "../../components/Dialogs/GeneralDialog";
import SearchIcon from "@material-ui/icons/Search";
import AddBoxIcon from "@material-ui/icons/AddBox";
import AddEdit from "./AddEdit";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";

let timeout;

const initialState = {
  prob_title: "",
  prob_detail: "",
  requirement: "",
  procedure: "",
  concerned_to: "",
};
const Supports = () => {
  const [expanded, setExpanded] = useState(false);
  const [query, setQuery] = useState({
    search: "",
    page: 0,
    pageSize: 10,
  });
  const [state, setState] = useState(initialState);

  const handleResult = (value, validity, name) => {
    setState({ ...state, [name]: value });
  };
  const [dialog, setDialog] = useState({
    open: false,
    edit: null,
  });
  const dispatch = useDispatch();
  const supports = useSelector((state) => state.supports);
  const isAuth = useSelector((state) => state.auth.token);
  const { primary_light_color, primary } = useContext(themeContext);

  const fetchSupports = () => {
    dispatch(actions.getSupports(query));
  };
  useEffect(() => {
    fetchSupports();
    // eslint-disable-next-line
  }, []);
  const handleExpand = (id) => {
    if (expanded !== id) {
      setExpanded(id);
    }
  };

  const handleSearch = (e) => {
    let searchVal = e.target.value;
    setQuery({ ...query, search: searchVal });
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      let q = {
        search: searchVal,
        page: query.page,
        pageSize: query.pageSize,
      };
      dispatch(actions.getSupports(q));
    }, 1500);
  };

  const handleTriggerDialog = () => {
    setState(initialState);
    setDialog({ open: !dialog.open, edit: null });
  };
  const handleEditItem = (item) => {
    setState({
      prob_title: item.prob_title,
      prob_detail: item.prob_detail,
      requirement: item.requirement,
      procedure: item.procedure,
      concerned_to: item.concerned_to,
    });
    setDialog({ open: !dialog.open, edit: item });
  };
  const handleAddEdit = async () => {
    try {
      dispatch(actions.saveSupport(state, dialog.edit));
      handleTriggerDialog();
    } catch (err) {
      dispatch(
        actions.fireSnake(
          "error",
          err.message ? err.message : "خطأ في الحفظ. حاول مرة أخرى"
        )
      );
    }
  };
  const handleNextPage = () => {
    setQuery({ ...query, page: query.page + 1 });
    dispatch(actions.getSupports({ ...query, page: query.page + 1 }));
  };
  const handlePrevPage = () => {
    setQuery({ ...query, page: query.page - 1 });
    dispatch(actions.getSupports({ ...query, page: query.page - 1 }));
  };
  const handlePerPage = (e) => {
    let perPage = e.target.value;
    setQuery({ ...query, pageSize: perPage });
    dispatch(actions.getSupports({ ...query, pageSize: perPage }));
  };
  return (
    <Container>
      <div className="flex hor flex-justify-between">
        <TextField
          variant="outlined"
          style={{ marginBottom: 10 }}
          placeholder="البحث..."
          onChange={handleSearch}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon color="disabled" />
              </InputAdornment>
            ),
          }}
        />
        {isAuth && (
          <AddBoxIcon
            color="primary"
            fontSize="large"
            className="hoverable-op"
            onClick={() => handleTriggerDialog()}
          />
        )}
      </div>
      {supports.supports.length <= 0 && !supports.loading && (
        <Typography>لا يوجد نتائج</Typography>
      )}
      {supports.loading && <LinearProgress color="primary" />}
      {supports.supports.map((supp) => (
        <ExpansionPanel
          key={supp.id}
          expanded={expanded === supp.id}
          onChange={() => handleExpand(supp.id)}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            style={{ background: primary_light_color }}
          >
            <div
              style={{
                width: 25,
                height: 25,
                borderRadius: 50,
              }}
              className={`${primary} flex flex-justify-center align-items-center mx`}
            >
              <Typography variant="caption" className="text-light">
                {supp.id}
              </Typography>
            </div>
            <Typography>{supp.prob_title}</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className="ver">
            <Accordion support={supp} bg={primary} setEdit={handleEditItem} />
          </ExpansionPanelDetails>
        </ExpansionPanel>
      ))}
      {supports.pagination && (
        <>
          <div className="my pagination">
            <Typography className="p">
              المجموع: {supports.pagination.totalCount}
            </Typography>
            <Typography className="p">
              {query.page >= 1 && (
                <Typography
                  component="span"
                  className="px hoverable-op"
                  onClick={handlePrevPage}
                >
                  <KeyboardArrowRightIcon style={{ verticalAlign: "middle" }} />
                  السابق
                </Typography>
              )}
              صفحة {query.page + 1} من{" "}
              {Math.ceil(supports.pagination.totalCount / query.pageSize)}
              {Math.ceil(supports.pagination.totalCount / query.pageSize) - 1 >
                query.page && (
                <Typography
                  component="span"
                  className="px hoverable-op"
                  onClick={handleNextPage}
                >
                  التالي
                  <KeyboardArrowLeftIcon style={{ verticalAlign: "middle" }} />
                </Typography>
              )}
            </Typography>
            <Select
              value={query.pageSize}
              onChange={handlePerPage}
              style={{ minWidth: 150 }}
              className="p"
              variant="outlined"
            >
              <MenuItem value={5}>5 لكل صفحة</MenuItem>
              <MenuItem value={10}>10 لكل صفحة</MenuItem>
              <MenuItem value={20}>20 لكل صفحة</MenuItem>
              <MenuItem value={30}>30 لكل صفحة</MenuItem>
            </Select>
          </div>
        </>
      )}
      {dialog.open && (
        <Dialog
          dialogOpen={dialog.open}
          onCancel={() => handleTriggerDialog()}
          fullWidth
          onConfirm={handleAddEdit}
          confirmText={dialog.edit ? "تعديل" : "حفظ"}
          loading={supports.loading}
          contentComponent={
            <AddEdit edit={dialog.edit} handleResult={handleResult} />
          }
        />
      )}
    </Container>
  );
};

export default Supports;
