import { readCookie } from "./cookies";

export const getCookieToken = () => {
  const cookie = readCookie("auth");
  const auth = JSON.parse(cookie);
  if (auth) {
    return auth;
  }
  return { token: null, expired: null, token_origine: null };
};
