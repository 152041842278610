import React from "react";
import Input from "../../components/UI/Inputs/Input";

const AddEdit = ({ edit, handleResult }) => {
  return (
    <div>
      <div className="py-s5">
        <Input
          name="prob_title"
          label="عنوان المشكلة"
          initialValue={edit ? edit.prob_title : ""}
          getResult={handleResult}
        />
      </div>
      <div className="py-s5">
        <Input
          name="prob_detail"
          label="تفاصيل المشكلة"
          initialValue={edit ? edit.prob_detail : ""}
          getResult={handleResult}
          multiline
          row={3}
        />
      </div>
      <div className="py-s5">
        <Input
          name="requirement"
          label="المطلوب"
          initialValue={edit ? edit.requirement : ""}
          getResult={handleResult}
          multiline
          row={2}
        />
      </div>
      <div className="py-s5">
        <Input
          name="procedure"
          label="الإجراء"
          initialValue={edit ? edit.procedure : ""}
          getResult={handleResult}
          multiline
          row={2}
        />
      </div>
      <div className="py-s5">
        <Input
          name="concerned_to"
          label="التوضيح/الجهة"
          initialValue={edit ? edit.concerned_to : ""}
          getResult={handleResult}
          multiline
          row={3}
        />
      </div>
    </div>
  );
};

export default AddEdit;
