import * as actionsTypes from "../actions/types";

const initialState = {
  user_id: 0,
  user_name: "",
  mobile: "",
  settings: [],
  role: null,
  permissions: [],
  isAuth: false,
  ready: false,
  loading: false,
  errors: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionsTypes.PROFILE_LOADING:
      return {
        ...state,
        loading: true,
        errors: null,
        ready: false,
      };

    case actionsTypes.GET_PROFILE:
      return {
        ...state,
        // user_id: action.user_id,
        user_name: action.profile.name,
        mobile: action.profile.mobile,
        // stores: action.stores,
        role: action.profile.role,
        settings: action.profile.settings,
        loading: false,
        // ready: true,
        // isAuth: true
      };
    case actionsTypes.SET_SETTING:
      return {
        ...state,
        loading: false,
        settings: action.settings,
        // ready: true,
        // isAuth: true
      };

    case actionsTypes.PROFILE_ERRORS:
      return {
        ...state,
        loading: false,
        isAuth: false,
        errors: action.errors,
        ready: true,
      };
    case actionsTypes.LOGOUT:
      return { ...initialState, ready: true };
    default:
      return state;
  }
};
