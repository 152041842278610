import * as actionTypes from "../actions/types";

const initialState = {
  supports: [],
  pagination: null,
  loading: false,
  errors: null,
};
export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_SUPPORTS:
      return {
        ...state,
        loading: false,
        supports: action.supports.supports,
        pagination: action.supports.pagination,
      };
    case actionTypes.NEW_SUPPORT:
      return {
        ...state,
        loading: false,
        supports: [...state.supports, action.support],
      };
    case actionTypes.UPDATE_SUPPORT:
      const updated = state.supports.map((sup) =>
        sup.id === action.support.id ? action.support : sup
      );
      return {
        ...state,
        loading: false,
        supports: updated,
      };
    case actionTypes.SUPPORTS_LOADING:
      return {
        ...state,
        loading: true,
        errors: null,
      };
    case actionTypes.SUPPORTS_ERRORS:
      return {
        ...state,
        loading: false,
        errors: action.errors,
      };
    default:
      return state;
  }
};
