import * as actionTypes from "./types";
import axios from "axios";
import { server } from "../../util/constants";
import setAuthHeaders from "../../util/setHeader";

const setErrors = (errors) => {
  return {
    type: actionTypes.SUPPORTS_ERRORS,
    errors,
  };
};
const loading = () => {
  return {
    type: actionTypes.SUPPORTS_LOADING,
  };
};

export const getSupports = (query) => {
  return async (dispatch) => {
    dispatch(loading());
    try {
      const { search, page, pageSize } = query;
      const res = await axios.get(
        `${server}/supports?q=${search}&page=${page}&pageSize=${pageSize}`
      );
      dispatch({ type: actionTypes.SET_SUPPORTS, supports: res.data });
      return res.data;
    } catch (err) {
      dispatch(setErrors(err.response.data));
    }
  };
};

export const saveSupport = (body, isEdit) => {
  return async (dispatch, getState) => {
    const { auth } = getState();
    setAuthHeaders(auth.token);
    dispatch(loading());
    try {
      if (!isEdit) {
        const res = await axios.post(server + "/supports", body);
        dispatch({ type: actionTypes.NEW_SUPPORT, support: res.data.support });
      } else {
        const res = await axios.put(server + "/supports/" + isEdit.id, body);
        dispatch({
          type: actionTypes.UPDATE_SUPPORT,
          support: res.data.support,
        });
      }
    } catch (err) {
      dispatch(setErrors(err.response.data));
      throw err.response ? err.response.data : err;
    }
  };
};
