import React from "react";
import ErrorIcon from "@material-ui/icons/Error";
import ListAltIcon from "@material-ui/icons/ListAlt";
import DetailsIcon from "@material-ui/icons/Details";
import SyncAltIcon from "@material-ui/icons/SyncAlt";
import { Typography } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import { useSelector } from "react-redux";

const Accordions = ({ bg, support, setEdit }) => {
  const auth = useSelector((state) => state.auth);
  return (
    <>
      {support.prob_detail ? (
        <div className="flex py-s5">
          <Item bg={bg}>
            <Typography variant="caption" className="text-light">
              <ErrorIcon
                fontSize="small"
                style={{ display: "flex", alignSelf: "center" }}
              />
            </Typography>
          </Item>
          <Typography variant="h6">
            تفاصيل المشكلة: {support.prob_detail}
          </Typography>
        </div>
      ) : null}
      {support.requirement ? (
        <div className="flex py-s5">
          <Item bg={bg}>
            <Typography variant="caption" className="text-light">
              <ListAltIcon
                fontSize="small"
                style={{ display: "flex", alignSelf: "center" }}
              />
            </Typography>
          </Item>
          <Typography variant="h6">المطلوب: {support.requirement}</Typography>
        </div>
      ) : null}
      {support.procedure ? (
        <div className="flex py-s5">
          <Item bg={bg}>
            <Typography variant="caption" className="text-light">
              <DetailsIcon
                fontSize="small"
                style={{ display: "flex", alignSelf: "center" }}
              />
            </Typography>
          </Item>
          <Typography variant="h6">الإجراء: {support.procedure}</Typography>
        </div>
      ) : null}
      {support.concerned_to ? (
        <div className="flex py-s5">
          <Item bg={bg}>
            <Typography variant="caption" className="text-light">
              <SyncAltIcon
                fontSize="small"
                style={{ display: "flex", alignSelf: "center" }}
              />
            </Typography>
          </Item>
          <Typography variant="h6">
            التوضيح الجهة: {support.concerned_to}
          </Typography>
        </div>
      ) : null}
      {auth.token && (
        <EditIcon
          fontSize="large"
          style={{ display: "flex", alignSelf: "flex-end" }}
          className="hoverable-op"
          onClick={() => setEdit(support)}
        />
      )}
    </>
  );
};

const Item = ({ bg, children }) => {
  return (
    <div
      style={{
        width: 30,
        height: 30,
        borderRadius: 50,
      }}
      className={`${bg} flex flex-justify-center align-items-center mx`}
    >
      {children}
    </div>
  );
};
export default Accordions;
