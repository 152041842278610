import React, { Fragment, useState } from "react";
import AppBar from "./AppBar";
import Footer from "./Footer";
import GeneralDialog from "../Dialogs/GeneralDialog";
import MyMobileInput from "../UI/Inputs/MobileInput/MobileInput";
import Input from "../UI/Inputs/Input";
import { Button, Typography, Switch } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../store/actions";
import ButtonSpinner from "../UI/Spinners/ButtonSpinner";

const Layout = ({ children }) => {
  const [openAuth, setOpenAuth] = useState(false);
  const [openSetting, setOpenSetting] = useState(false);
  const [state, setState] = useState({
    mobile: "",
    password: "",
  });
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  //Setting
  const handleSettingsDialog = () => {
    setOpenSetting(!openSetting);
  };

  //Signin
  const handleAuthDialog = () => {
    setOpenAuth(!openAuth);
  };
  const handleResult = (value, validity, name) => {
    setState({ ...state, [name]: value });
  };
  const handleLogin = async () => {
    try {
      await dispatch(actions.login(state));
      handleAuthDialog();
    } catch (err) {}
  };
  return (
    <Fragment>
      <AppBar
        AuthDialog={handleAuthDialog}
        SettingDialog={handleSettingsDialog}
      />
      <main>{children}</main>
      <Footer />
      {openAuth && (
        <GeneralDialog
          dialogOpen={openAuth}
          title="دخول الأدمن"
          onCancel={handleAuthDialog}
          fullWidth
          withoutConfirmBtn
          contentComponent={
            <div>
              <MyMobileInput getResult={handleResult} />
              <Input
                type="password"
                getResult={handleResult}
                label="كلمة المرور"
                variant="standard"
                name="password"
              />
              {auth.errors && (
                <Typography className="text-error">
                  {auth.errors.message ? auth.errors.message : ""}
                </Typography>
              )}
              <Button
                color="primary"
                variant="contained"
                className="py-1"
                style={{ marginTop: 15, float: "left" }}
                onClick={handleLogin}
                endIcon={auth.loading ? <ButtonSpinner /> : null}
                disabled={auth.loading}
              >
                تسجيل دخول
              </Button>
            </div>
          }
        />
      )}
      {openSetting && (
        <GeneralDialog
          dialogOpen={openSetting}
          title="إعدادات"
          onCancel={handleSettingsDialog}
          fullWidth
          withoutConfirmBtn
          contentComponent={
            <Switch
              checked={true}
              // onChange={e =>
              //   handleChange(e.target.checked ? "1" : "0", set.name)
              // }
              name={"set.name"}
              color="primary"
              // disabled={settings.loading}
            />
          }
        />
      )}
    </Fragment>
  );
};

export default Layout;
