import React, { useContext } from "react";
import themeContext from "../../../../contexts/theme/themeContext";
import { Button } from "@material-ui/core";

const CancelBtn = ({ onClick, variant, color, startIcon, children }) => {
  const { gradient_third } = useContext(themeContext);
  return (
    <Button
      onClick={onClick}
      variant={variant}
      color={color}
      className={gradient_third}
      startIcon={startIcon}
    >
      {children}
    </Button>
  );
};

export default CancelBtn;
