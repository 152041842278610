import * as actionTypes from "../actions/types";

const initialState = {
  contacts: [],
  pagination: null,
  loading: false,
  errors: null,
};
export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_CONTACTS:
      return {
        ...state,
        loading: false,
        contacts: action.contacts.contacts,
        pagination: action.contacts.pagination,
      };
    case actionTypes.NEW_CONTACT:
      return {
        ...state,
        loading: false,
        contacts: [...state.contacts, action.contact],
      };
    case actionTypes.UPDATE_CONTACT:
      const updated = state.contacts.map((con) =>
        con.id === action.contact.id ? action.contact : con
      );
      return {
        ...state,
        loading: false,
        contacts: updated,
      };
    case actionTypes.CONTACTS_LOADING:
      return {
        ...state,
        loading: true,
        errors: null,
      };
    case actionTypes.CONTACTS_ERRORS:
      return {
        ...state,
        loading: false,
        errors: action.errors,
      };
    default:
      return state;
  }
};
