import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

const PageSpinner = ({ color }) => {
  return (
    <CircularProgress
      size="3rem"
      color="inherit"
      className={color === "light" ? "text-light" : ""}
    />
  );
};

export default PageSpinner;
