import { combineReducers } from "redux";
import authReducer from "./auth";
import profileReducer from "./profile";
import snakebarReducer from "./globalsnakebar";
import contactReducer from "./contacts.js";
import suppottReducer from "./supports";

export default combineReducers({
  auth: authReducer,
  profile: profileReducer,
  snakebar: snakebarReducer,
  contacts: contactReducer,
  supports: suppottReducer,
});
