import React from "react";

const context = React.createContext({
  theme: {},
  toggleDarkMode: () => {},
  template: "",
  primary: "",
  primary_light: "",
  primary_light_color: "",
  secondary: "",
  gradient_primary: "",
  gradient_secondary: "",
  gradient_third: "",
  language: "",
  toggleLanguage: () => {}
});

export default context;
