import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import ThemeState from "./contexts/theme/ThemeState";
import { Provider } from "react-redux";
import "./index.css";
import store from "./store/store";
import { BrowserRouter } from "react-router-dom";
import { CssBaseline } from "@material-ui/core";

ReactDOM.render(
  // <React.StrictMode>
  <Provider store={store}>
    <CssBaseline />
    <ThemeState>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ThemeState>
  </Provider>,
  // </React.StrictMode>
  document.querySelector("#root")
);
