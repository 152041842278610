import React, { useEffect, useContext } from "react";
import { ThemeProvider } from "@material-ui/core/styles";
import themeContext from "./contexts/theme/themeContext";
import GlobalSnakebar from "./components/UI/GlobalSnakebar/SnakeBar";
import Home from "./views/Home/Home";
import Loader from "./views/Loader";
import { useDispatch, useSelector } from "react-redux";
import { tryAutoAuth } from "./store/actions";

const App = ({ history }) => {
  const { theme } = useContext(themeContext);
  const dispatch = useDispatch();
  const { ready } = useSelector((state) => state.auth);

  const ifAuth = async () => {
    try {
      await dispatch(tryAutoAuth());
    } catch (err) {
      // dispatch(getSettings());
    }
  };
  useEffect(() => {
    ifAuth();
    // eslint-disable-next-line
  }, []);
  if (!ready) {
    return <Loader text="تحميل" />;
  }
  return (
    <ThemeProvider theme={theme}>
      <Home />
      <GlobalSnakebar />
    </ThemeProvider>
  );
};

export default App;
