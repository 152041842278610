import * as actionTypes from "./types";
import axios from "axios";
import { createCookie, eraseCookie } from "../../util/cookies";
import setAuthHeader from "../../util/setHeader";
import { server } from "../../util/constants";
import { getCookieToken } from "../../util/getCookieToken";

const loading = () => {
  return {
    type: actionTypes.AUTH_LOADING,
  };
};
const setErrors = (err) => {
  return {
    type: actionTypes.AUTH_ERRORS,
    errors: err && err.data ? err.data : err,
  };
};

export const login = (state) => {
  return async (dispatch) => {
    dispatch(loading());
    try {
      const { mobile, password } = state;
      let ebile = mobile;
      const res = await axios.post(server + "/signin", {
        ebile,
        password,
      });
      createCookie(
        "auth",
        JSON.stringify({
          token: res.data.token,
          expired: res.data.expiresIn,
        })
      );
      dispatch({
        type: actionTypes.LOGIN,
        token: res.data.token,
        expiresIn: res.data.expiresIn,
      });
      dispatch({ type: actionTypes.GET_PROFILE, profile: res.data.profile });
    } catch (err) {
      dispatch(setErrors(err.response));
      throw err.response ? err.response.data : err;
    }
  };
};

export const logout = () => {
  return async (dispatch) => {
    eraseCookie("auth");
    // try {
    // const res = await axios.post("/user/logout");
    // console.log(res);
    // eraseCookie("auth");
    // setAuthHeader(null, (cb) => {
    //   dispatch({ type: actionTypes.LOGOUT });
    // });
    // } catch (err) {
    // dispatch(setErrors(err.response));
    // throw err.response ? err.response.data : err;
    // }
  };
};

export const tryAutoAuth = () => async (dispatch) => {
  console.log("TRY AUTH");
  try {
    const auth = getCookieToken();
    const { token, expired } = auth;
    if (token && expired) {
      setAuthHeader(token);
      const res = await axios.post(server + "/auth", { expiresIn: expired });
      console.log("AUTH SUCCESS");
      dispatch({
        type: actionTypes.LOGIN,
        token: res.data.token,
        expiresIn: res.data.expiresIn,
      });
      dispatch({ type: actionTypes.GET_PROFILE, profile: res.data.profile });
      console.log(res.data);
    } else {
      console.log("No token");
      dispatch(setErrors(null));
    }
  } catch (err) {
    console.log("AUTH FAIL");
    dispatch(logout());
    dispatch(setErrors());
    throw err;
  }
};
export const getSettings = () => {
  return async (dispatch) => {
    dispatch(loading());
    try {
      const res = await axios.get(server + "/settings");
      dispatch({ type: actionTypes.SET_SETTING, settings: res.data });
    } catch (err) {
      console.log(err);
      dispatch(setErrors(err.response.data));
    }
  };
};
