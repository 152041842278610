import React, { useReducer } from "react";
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import ThemeContext from "./themeContext";
import reducer, { getPalette, getCSSThemeBG } from "./themeReducer";
import { create } from "jss";
import rtl from "jss-rtl";
import { StylesProvider, jssPreset } from "@material-ui/core/styles";

const initialState = {
  palette: getPalette("second"),
  typography: {
    fontFamily: ["Cairo", "Roboto"].join(","),
    h1: {
      fontFamily: ["Cairo-bold", "Roboto"].join(","),
      fontSize: "3rem",
      letterSpacing: "-0.007em",
    },
    h2: {
      fontFamily: ["Cairo-semi", "Roboto"].join(","),
      fontSize: "2.5rem",
      letterSpacing: "-0.01em",
    },
    h3: {
      fontFamily: ["Cairo-semi", "Roboto"].join(","),
      fontSize: "2.2rem",
      letterSpacing: "-0.01em",
    },
    h4: {
      fontFamily: ["Cairo-semi", "Roboto"].join(","),
      fontSize: "2rem",
      letterSpacing: "-0.01em",
    },
  },
  direction: "rtl",
  overrides: {
    MuiFormLabel: {
      root: {
        fontSize: "0.8rem",
      },
    },
    MuiAutocomplete: {
      option: {
        "&:hover": { background: "#8ea2b3" },
      },
      inputRoot: {
        '&[class*="MuiOutlinedInput-root"]': {
          padding: 1,
        },
      },
    },
    MuiOutlinedInput: {
      input: {
        padding: 10,
      },
    },
    MuiInputLabel: {
      outlined: {
        transform: `translate(${14}px, ${12}px) scale(1)`,
      },
    },
    MuiFormHelperText: {
      root: {
        fontSize: "0.65rem",
      },
    },
    MuiInputBase: {
      root: {
        fontSize: "0.9rem",
      },
    },
    // MuiDialog: {
    //   paperFullWidth: {
    //     maxWidth: "85%",
    //   },
    // },
    MuiInput: {
      underline: {
        "&$disabled": {
          "&:before": { borderBottomStyle: "solid" },
        },
      },
    },
  },
  css: {
    ...getCSSThemeBG("second"),
  },
  language: "ar-AR",
};

const ThemeState = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const theme = createMuiTheme(state);

  const toggleDarkMode = () => {
    if (state.palette.type === "light") {
      dispatch({
        type: "TOGGLE_DARK",
        payload: "dark",
      });
    } else {
      dispatch({
        type: "TOGGLE_DARK",
        payload: "light",
      });
    }
  };

  const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

  return (
    <ThemeContext.Provider
      value={{
        theme: theme,
        template: state.css.template,
        primary: state.css.primary,
        primary_light: state.css.primary_light,
        primary_light_color: state.css.primary_light_color,
        secondary: state.css.secondary,
        gradient_primary: state.css.gradient_primary,
        gradient_secondary: state.css.gradient_secondary,
        gradient_third: state.css.gradient_third,
        toggleDarkMode,
      }}
    >
      <StylesProvider jss={jss}>{props.children}</StylesProvider>
    </ThemeContext.Provider>
  );
};

// function RTL(props) {
//   return <StylesProvider jss={jss}>{props.children}</StylesProvider>;
// }

export default ThemeState;
