import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { Link, Tooltip } from "@material-ui/core";

const Footer = () => {
  const classes = useStyles();
  return (
    <AppBar
      component="footer"
      position="fixed"
      color="primary"
      className={classes.appBar}
    >
      <Toolbar style={{ minHeight: 50 }}>
        <div className="flex align-items-center ">
          <img
            src="/logo192.png"
            style={{ maxWidth: 35 }}
            alt="عمادة تقنية المعلومات"
          />
          <Typography variant="caption">
            عمادة تقنية المعلومات - م.تركي التركي
          </Typography>
        </div>
        <div style={{ flexGrow: 1 }} />

        <Link
          href="http://ium.bnturki.com/ium-it-support.apk"
          // target="_blank"
          // rel="noopener"
        >
          <div className="bg-androind" />
        </Link>
        <Tooltip title="SOON">
          <div className="bg-apple" />
        </Tooltip>
      </Toolbar>
    </AppBar>
  );
};

const useStyles = makeStyles((theme) => ({
  appBar: {
    top: "auto",
    bottom: 0,
    zIndex: -1,
  },
  grow: {
    flexGrow: 1,
  },
}));

export default Footer;
