import * as actionTypes from "./types";

export const fireSnake = (variant, message, vertical, horizontal) => {
  return {
    type: actionTypes.FIRE_SNAKEBAR,
    variant,
    message,
    vertical,
    horizontal
  };
};
export const clearSnake = () => {
  return {
    type: actionTypes.CLEAR_SNAKEBAR
  };
};
