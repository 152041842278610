import React, { useState, useEffect } from "react";
import Data from "./Countries";
import { Typography, TextField, Select, MenuItem } from "@material-ui/core";
import { useDispatch } from "react-redux";
import * as actions from "../../../../store/actions";

const MyMobileInput = (props) => {
  const { getResult, inputref } = props;

  const [state, setState] = useState({
    mobile: "",
    flag: Data.filter((flag) => flag.code === "SA")[0].flag,
    code: Data.filter((flag) => flag.code === "SA")[0].dial_code,
  });
  const dispatch = useDispatch();
  const handleChange = (name, value, first) => {
    let filteredVal = value;
    if (first && value.charAt(0) === "0") {
      filteredVal = value.substring(1);
    }
    setState({ ...state, [name]: filteredVal });
  };

  useEffect(() => {
    getResult(state.code + state.mobile, state.mobile.length > 7, "mobile");
    // eslint-disable-next-line
  }, [state]);

  const handleChooseCountry = async (code) => {
    const countryData = await Data;
    try {
      const country = await countryData.find((cont) => cont.dial_code === code);
      setState({
        ...state,
        flag: country.flag,
        code: country.dial_code,
      });
    } catch (err) {
      dispatch(
        actions.fireSnake(
          "error",
          err.message ? err.message : "خطأ في إختيار كود الدولة. عاود الإختيار"
        )
      );
    }
  };

  return (
    <div className="flex">
      <TextField
        style={{ direction: "ltr", flexGrow: 1 }}
        placeholder="507487620"
        value={state.mobile}
        ref={inputref}
        onChange={(e) => {
          const val = e.target.value;
          if (state.mobile === "") {
            handleChange("mobile", val, "first");
          } else {
            handleChange("mobile", val);
          }
        }}
        inputProps={{ style: { fontSize: 16, padding: 9 } }}
      />
      <Select
        defaultValue={state.code}
        onChange={(e) => handleChooseCountry(e.target.value)}
        variant="standard"
      >
        {Data.map((c) => (
          <MenuItem key={c.dial_code} value={c.dial_code}>
            <Typography style={{ direction: "ltr" }}>
              {c.flag + " " + c.dial_code}-{" "}
            </Typography>
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

export default MyMobileInput;
