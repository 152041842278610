//Snakebar
export const FIRE_SNAKEBAR = "FIRE_SNAKEBAR";
export const CLEAR_SNAKEBAR = "CLEAR_SNAKEBAR";

//Auth
export const AUTH_LOADING = "AUTH_LOADING";
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const AUTH_ERRORS = "AUTH_ERRORS";

//Profile
export const GET_PROFILE = "GET_PROFILE";
export const SET_SETTING = "SET_SETTING";
export const PROFILE_LOADING = "PROFILE_LOADING";
export const PROFILE_ERRORS = "PROFILE_ERRORS";

//Contacts
export const SET_CONTACTS = "SET_CONTACTS";
export const NEW_CONTACT = "NEW_CONTACT";
export const UPDATE_CONTACT = "UPDATE_CONTACT";
export const CONTACTS_LOADING = "CONTACTS_LOADING";
export const CONTACTS_ERRORS = "CONTACTS_ERRORS";
//SUPPORTS
export const SET_SUPPORTS = "SET_SUPPORTS";
export const NEW_SUPPORT = "NEW_SUPPORT";
export const UPDATE_SUPPORT = "UPDATE_SUPPORT";
export const SUPPORTS_LOADING = "SUPPORTS_LOADING";
export const SUPPORTS_ERRORS = "SUPPORTS_ERRORS";
