import React from "react";
import { ButtonGroup, Button } from "@material-ui/core";

const SwitchButtons = ({ buttons, state, onClick, fullWidth, disabled }) => {
  return (
    <ButtonGroup fullWidth={fullWidth} className="p-1">
      {buttons.map((btn, i) => (
        <Button
          key={i}
          color={state === btn.name ? "primary" : "default"}
          variant={state === btn.name ? "contained" : "outlined"}
          onClick={() => onClick(btn.name)}
          disabled={disabled}
          style={{ display: "block", paddingTop: 20, paddingBottom: 20 }}
        >
          {btn.icon}
          {btn.content}
        </Button>
      ))}
    </ButtonGroup>
  );
};

export default SwitchButtons;
