import React, { useState } from "react";
import { Container, Typography } from "@material-ui/core";
import Layout from "../../components/Layout/Layout";
import SwitchButtons from "../../components/UI/Buttons/SwitchButtons/SwitchButtons";
import ContactsIcon from "@material-ui/icons/Contacts";
import LiveHelpIcon from "@material-ui/icons/LiveHelp";
import Contacts from "../Contacts/Contacts";
import Supports from "../Supports/Supports";

const Home = () => {
  const [active, setActive] = useState("contacts");
  const handleChange = (e) => {
    setActive(e);
  };
  const buttons = [
    {
      name: "contacts",
      content: <Typography>العناوين و وسائل التواصل</Typography>,
      icon: <ContactsIcon fontSize="large" />,
    },
    {
      name: "supports",
      content: <Typography>المشاكل وحلول الدعم</Typography>,
      icon: <LiveHelpIcon fontSize="large" />,
    },
  ];
  let table = <Contacts />;
  if (active === "supports") {
    table = <Supports />;
  }
  return (
    <Layout>
      <Container>
        <SwitchButtons
          buttons={buttons}
          state={active}
          onClick={handleChange}
          fullWidth
        />
        {table}
      </Container>
    </Layout>
  );
};

// const useStyle = makeStyles({
// root: {
//   height: "100vh",
//   display: "flex",
//   alignItems: "center",
//   justifyContent: "center",
//   background: "#bbb"
// }
// });

export default Home;
