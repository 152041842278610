import * as actionTypes from "../actions/types";

const initialState = {
  open: false,
  variant: "warning",
  message: "مرحبا",
  vertical: "bottom",
  horizontal: "left"
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FIRE_SNAKEBAR:
      return {
        open: true,
        variant: action.variant,
        message: action.message,
        vertical: action.vertical ? action.vertical : "bottom",
        horizontal: action.horizontal ? action.horizontal : "left"
      };
    case actionTypes.CLEAR_SNAKEBAR:
      return {
        ...state,
        open: false
      };

    default:
      return state;
  }
};
