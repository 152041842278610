import React from "react";
import Input from "../../components/UI/Inputs/Input";

const AddEdit = ({ edit, handleResult }) => {
  return (
    <div>
      <div className="py-s5">
        <Input
          name="name"
          label="إسم الجهة"
          initialValue={edit ? edit.name : ""}
          getResult={handleResult}
        />
      </div>
      <div className="py-s5">
        <Input
          name="tel"
          label="رقم تلفون"
          initialValue={edit ? edit.tel : ""}
          getResult={handleResult}
        />
      </div>
      <div className="py-s5">
        <Input
          name="mobile"
          label="جوال"
          initialValue={edit ? edit.mobile : ""}
          getResult={handleResult}
        />
      </div>
      <div className="py-s5">
        <Input
          name="whatsapp"
          label="واتساب"
          initialValue={edit ? edit.whatsapp : ""}
          getResult={handleResult}
        />
      </div>
      <div className="py-s5">
        <Input
          name="twitter"
          label="تويتر"
          initialValue={edit ? edit.twitter : ""}
          getResult={handleResult}
        />
      </div>
      <div className="py-s5">
        <Input
          name="email"
          label="إيميل"
          initialValue={edit ? edit.email : ""}
          getResult={handleResult}
        />
      </div>
    </div>
  );
};

export default AddEdit;
