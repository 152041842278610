import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import ClearIcon from "@material-ui/icons/Clear";
import ButtonSpinner from "../UI/Spinners/ButtonSpinner";
import CancelBtn from "../../components/UI/Buttons/CancelButton/CancelBtn";

const GeneralDialog = ({
  dialogOpen,
  onCancel,
  onConfirm,
  loading,
  disabled,
  title,
  subtitle,
  contentComponent,
  confirmText,
  confirmIcon,
  backText,
  backIcon,
  fullWidth,
  withoutConfirmBtn,
}) => {
  return (
    <Dialog
      open={dialogOpen}
      onClose={onCancel}
      aria-labelledby="dialog-title"
      fullWidth={fullWidth}
    >
      {title && (
        <DialogTitle id="dialog-title" style={{ textAlign: "center" }}>
          {title}
        </DialogTitle>
      )}
      {(subtitle || contentComponent) && (
        <DialogContent>
          {contentComponent}
          <DialogContentText>{subtitle}</DialogContentText>
        </DialogContent>
      )}
      <DialogActions
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: 15,
        }}
      >
        <CancelBtn
          onClick={onCancel}
          variant="contained"
          color="secondary"
          startIcon={backIcon ? backIcon : <ClearIcon />}
        >
          {backText ? backText : "إلغاء"}
        </CancelBtn>
        {!withoutConfirmBtn && (
          <Button
            onClick={onConfirm}
            variant="contained"
            color="primary"
            startIcon={confirmIcon ? confirmIcon : null}
            endIcon={loading ? <ButtonSpinner /> : null}
            disabled={loading || disabled}
          >
            {confirmText}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default GeneralDialog;
